import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { App } from "./App";
import * as serviceWorker from "./serviceWorker";

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// function onUpdateHandler(registration: ServiceWorkerRegistration) {

//   // Make sure that any new version of a service worker will take over the page
//   // and become activated immediately.
//   const waitingServiceWorker = registration.waiting;
//   if (waitingServiceWorker) {
//     waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
//   }

//   const notification = document.getElementById("reload");

//   const link = document.getElementById('reload-button');

//   if (notification) {
//     notification.style.display = 'block';
//   }

//   if (link) {
//     link.addEventListener("click", e => {
//       e.preventDefault();
//       window.location.reload();
//     });
//   }
// }
