import React, { Suspense } from 'react';
import 'typeface-roboto';
import { MuiThemeProvider, createMuiTheme, CircularProgress } from '@material-ui/core';

const Router = React.lazy(() => import('./Router'));

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#66AE32',
      contrastText: '#FFF'
    }
  },
});

export const App = () => (
  <div>
    <Suspense fallback={
      <MuiThemeProvider theme={theme}>
        <div style={{ width: '100%', textAlign: 'center', marginTop: '50vh' }}>
          <CircularProgress />
        </div>
      </MuiThemeProvider>
    }>
      <Router />
    </Suspense>
  </div>
  
);